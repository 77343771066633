<template>

<div class="rickArealist table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">白名单详情列表</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;">
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addWhite">新增白名单</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="高危地区ID" prop="id" width="300px" show-overflow-tooltip></el-table-column>
            <el-table-column label="代理商名称" prop="agentTreeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" class="themed-button"  @click="deleteById(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog title="新增白名单" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="200px" :model="form">
            <el-form-item label="代理商">
                <el-select v-model="form.agentId" filterable>
                    <el-option v-for="(item,index) in agentList" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="onSure">提交</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {getRiskAreaWhiteList,riskAreaWhiteInsert,riskAreaWhiteDelete,cardriskarea,getAgentList} from '@/api/cardManagement/rickArealist.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import resize from '@/mixins/resize.js'

import {provinceList} from '@/api/area.js'

export default {
    name:'rickArealist',
    mixins:[resize],
    data(){
        return {
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            form:{
                agentId:'',
                riskAreaId:''
            },
            agentList:[],
            // riskAreaList:[]
        }
    },
    computed:{
        riskAreaId(){
            let params = this.$route.query
            let riskAreaId = ''
            if(params.riskAreaId){
               riskAreaId = params.riskAreaId 
            }
            return riskAreaId
        },
    },
    mounted(){
        this._getAgentList()
        // this._cardriskarea()
        this._getRiskAreaWhiteList()
    },
    methods:{
        deleteById(id){
            confirmBox(this,'确定要删除吗？','消息提示',()=>{
                riskAreaWhiteDelete({id}).then((res)=>{
                   messageBox(this,'删除成功') 
                   this._getRiskAreaWhiteList()
                })
            })
        },
        addWhite(){
            this.dialogBox = true
        },
        onSure(){
            this.form.riskAreaId = this.riskAreaId
            riskAreaWhiteInsert(this.form).then((res)=>{
                messageBox(this,'添加成功')
                this._getRiskAreaWhiteList()
                this.dialogBox = false
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._getRiskAreaWhiteList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._getRiskAreaWhiteList()
        },
        _getRiskAreaWhiteList(){
            let params = {
                riskAreaId:this.riskAreaId,
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            getRiskAreaWhiteList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        // _cardriskarea(){
        //     cardriskarea({importType:2}).then((res)=>{
        //         if(res.rows.length){
        //             this.riskAreaList = res.rows.map((item)=>{
        //                 return {
        //                     id:item.id,
        //                     name:`${item.province} ${item.city} ${item.province}`
        //                 }
        //             })
        //         }else{
        //             this.riskAreaList = []
        //         }
                
        //     })
        // },
        _getAgentList(){
            getAgentList().then((res)=>{
                this.agentList = res.map((item)=>{
                    return {
                        id:item.agentThreeId,
                        name:item.name
                    }
                })
            })
        }
    }
}

</script>
