/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function cardriskarea(params){
    return request({
        url: 'cardriskarea/grid.do?',
        method: 'post',
        data:params
    })
}

export function del(params){
    return request({
        url: 'cardriskarea/del.do?',
        method: 'post',
        data:params
    })
}
export function add(params){
    return request({
        url: 'cardriskarea/add.do?',
        method: 'post',
        data:params
    })
}
export function update(params){
    return request({
        url: 'cardriskarea/update.do?',
        method: 'post',
        data:params
    })
}
export function getRiskAreaWhiteList(params){
    return request({
        url: 'riskAreaWhite/getList.do?',
        method: 'post',
        data:params
    })
}
export function riskAreaWhiteInsert(params){
    return request({
        url: 'riskAreaWhite/insert.do?',
        method: 'post',
        data:params
    })
}
export function riskAreaWhiteDelete(params){
    return request({
        url: 'riskAreaWhite/deleteById.do?',
        method: 'post',
        data:params
    })
}
export function getAgentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}